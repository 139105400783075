var VueScrollTo = require('vue-scrollto');

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

const app = new Vue({
    delimiters: ['{{','}}'],
    el: '#app',
    data: function(){
        return {
            currentSection: ''
        }
    },
    mounted: function() {
    },
    watch: {
    },
    methods: {
        openToSection: function(section) {
            if (this.currentSection == section) {
                this.currentSection = '';
            } else {
                this.currentSection = section;
            }
        },

        closeSection: function() {
            this.currentSection = '';
        },
    }
})